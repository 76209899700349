#screen-showcase {
	padding: 5rem 1rem 1rem 1rem;

	.timeline-container {
		padding: 0 3rem 3rem 3rem;
		max-width: 90rem;
		margin: auto;

		ul.project-timeline {
			padding-top: 5rem;
			padding-bottom: 5rem;
			margin-left: 5rem;

			li.project-entry {
				margin-top: 2rem;


				&:before {
					margin-top: -0.75em;
				}

				.project-year {
					position: absolute;
					right: calc(100% + 2rem);
					top: 0;
					margin-top: -0.75em;
				}


				.project-container {
					padding: 2rem;
					margin-left: 1rem;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: space-between;
					gap: 1rem 0;
					background-color: lighten($backgroundLightColor, 10%);
					border-radius: 0 1rem 1rem;

					&:before {
						content: "";
						position: absolute;
						left: 0;
						top: 0;
						border-color: lighten($backgroundLightColor, 10%);
						border-width: 1rem;
						border-style: solid;
						border-left-color: transparent;
						border-bottom-color: transparent;
					}

					.project-title {
						flex: 1 0 50%;
						max-width: max-content;
						min-width: fit-content;
						display: flex;
						padding-right: 1rem;
					}

					.project-tags {
						display: flex;
						flex: 1 1 50%;
						gap: 1rem;
						min-width: fit-content;
						justify-content: flex-end;


						.project-tag {
							background: $backgroundLightColor;
							border-radius: 5px;
							padding: 0.25rem 0.5rem;
						}
					}

					.project-details {
						display: flex;
						flex: 1 1 100%;
						min-width: 100%;
						gap: 1rem;

						.project-image-column {
							flex: 1 0 10rem;
							max-width: 40%;
							display: flex;
							flex-direction: column;

							.project-image {
								max-width: 30rem;
								max-height: 40rem;
							}
						}

						.project-description {
							flex: 1 1 auto;
							position: relative;
							padding-bottom: 2.5rem;
							white-space: pre-wrap;

							.project-github-link {
								position: absolute;
								right: 0;
								bottom: 0;
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1000px) {
		& {
			.timeline-container {
				padding: 1rem;

				ul.project-timeline {
					margin-left: 0;
					padding-left: 0;
					padding-top: 0;
					border: none;

					li.project-entry {

						&:before,
						.project-year{
							display: none;
						}

						.project-container {
							flex-direction: column;
							justify-content: flex-start;
							align-items: flex-start;
							margin-left: 0;
							border-top-left-radius: 1rem;

							&:before {
								display: none;
							}

							.project-tags {
								flex-wrap: wrap;
								justify-content: flex-start !important;
							}

							.project-details {
								flex-direction: column;

								.project-image-column {
									max-width: 100%;

									img {
										width: 100%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
