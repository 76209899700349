/* Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: $themeColor transparent;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
	width: 10px;
}
*::-webkit-scrollbar-track {
	background-color: transparent;
}

*::-webkit-scrollbar-track:hover {
	background-color: transparent;
}

*::-webkit-scrollbar-track:active {
	background-color: transparent;
}

*::-webkit-scrollbar-thumb {
	background-color: $themeColor;
}

*::-webkit-scrollbar-thumb:hover {
	background-color: $highlightColor;
}

*::-webkit-scrollbar-thumb:active {
	background-color: $highlightColor;
}
