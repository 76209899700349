#screen-aboutme {
	@extend .content-center;
	padding: 10rem 1rem 1rem 1rem;
	justify-content: flex-start !important;

	.about-container {
		width: 100%;
		max-width: 65rem;

		.about-head {
			justify-content: space-between;
			align-items: flex-start;
			gap: 2rem;
			margin-bottom: 2rem;

			img.profile-picture {
				border-radius: 50%;
			}

			.profile-introduction {

			}

		}

		.about-details {
			justify-content: space-between;
			gap: 2rem;
			flex-wrap: wrap;

			.flex-col {
				min-width: 15rem;
			}

			ul > li {
				margin-bottom: 1rem;
			}
		}
	}


	@media screen and (max-width: 1000px) {
		& {
			padding-top: 7rem;
		}
	}

	@media screen and (max-width: 600px) {
		& {
			.about-container {
				.about-head {
					flex-direction: column;
					align-items: center;
				}
			}
		}
	}
}