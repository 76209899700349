#screen-gadget {
	display: flex;
	justify-content: center;
	align-items: center;

	.gadget-container {
		width: 100vw;
		height: 100vh;
		overflow: hidden auto;

		@import "gadgets/GadgetList";
		@import "gadgets/StudyManager";
		@import "gadgets/FlowChart";
		@import "gadgets/TimsortVisualization";
		@import "gadgets/ListmeApp";
	}
}