$backgroundColor: #212121;
$backgroundLightColor: #323232;
$themeColor: #0D7377;
$highlightColor: #0dab9e;
$white: #eee;

$danger: #ea2e2e;

$font-weight-light: 200;
$font-weight-regular: 400;
$font-weight-bold: 700;