.listme-app {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 1rem;

	.description {
		background-color: $backgroundColor;
		display: flex;
		align-items: center;
		max-width: 50rem;
		border-radius: 1rem;
		overflow: hidden;
		gap: 0.5rem;

		@media screen and (max-width: 500px) {
			& {
				flex-direction: column;
				padding-bottom: 1rem;

				div {
					padding: 1rem;
					text-align: center;
				}

				img {
					border-radius: 0.5rem;
				}
			}
		}

		div {
			padding: 0.5rem 1rem;
		}

		img {
			width: 10rem;
		}
	}

	.feature-container {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;

		.feature {
			background-color: $backgroundColor;
			padding: 1rem;
			border-radius: 1rem;
			text-align: center;
		}
	}

	table {
		border-collapse: collapse;

		tr {
			td {
				padding: 0.5rem 1rem;

				&.sha {
					line-break: anywhere;
				}
			}
		}

		thead tr {
			border-bottom: 2px solid $highlightColor;
		}

		tbody tr {
			&:nth-child(odd) {
				background-color: $backgroundColor;
			}
		}
	}
}