.full-size {
	width: 100%;
	height: 100%;
}

.content-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.content-stretched {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.simple-col {
	flex: 1;
}