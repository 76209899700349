.h1 {
	font-size: 4rem;
	font-weight: $font-weight-bold;
}

.h2 {
	font-size: 3rem;
	font-weight: $font-weight-bold;
}

.h3 {
	font-size: 2rem;
	font-weight: $font-weight-bold;
}

.h4 {
	font-size: 1.5rem;
	font-weight: $font-weight-bold;
}

a,*:link,*:visited {
	text-decoration: none;
	font-style: italic;
	color: $highlightColor;
}

.emphasized {
	text-transform: uppercase;
	font-weight: bold;
	color: darken($white, 30%)
}

.striked {
	text-decoration: line-through;
}

i.fac-icon {
	width: 1em;
	height: 1em;
	vertical-align: -.125em;

	svg {
		fill: currentColor;
		stroke: currentColor;
		width: 1em;
		height: 1em;
	}
}

.info-tag {
	display: inline-flex;

	span:first-of-type {
		background-color: $backgroundLightColor;
		border-radius: 5px 0 0 5px;
		padding: 0.25rem 1rem;
		white-space: nowrap;
	}

	span:last-of-type {
		border-radius: 0 5px 5px 0;
		padding: 0.25rem 1rem;
		background-color: $themeColor;
		white-space: nowrap;
	}
}
