#screen-404 {

	.glass-pane {
		position: relative;
		padding: 3rem;
		border-radius: 1rem;
		overflow: hidden;
		backdrop-filter: blur(10px);
		text-align: center;

		.h1,.message {
			margin-bottom: 2rem;
		}

		.space-text {
			background-clip: text;
			color: transparent;
			text-transform: uppercase;
			background-position: center bottom;
			filter: drop-shadow(0px 0px 2px #fff);
			background-size: revert;
		}
	}
}